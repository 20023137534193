import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Box, Card, CardContent, CircularProgress, Grid } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
    ConsentState,
    callbackConsentRequestAsync,
    fetchAddressDetails,
    standardKycCallbackRequestAsync,
} from "../redux/ConsentSlice";

import verifiedLogo from "../images/Tick.svg";
import logo from "../images/edgrologo.png";
import "../styles/ConsentCallback.scss";
import AddressVerificationModal from "../../../modals/UserVerifiedAddressDetails";

function ConsentCallback() {
    const [countDown, setCountdown] = useState(0);
    const params = useParams();
    const searchQuery = new URLSearchParams(useLocation().search);
    const dispatch = useAppDispatch();
    const { callbackResult, loading, error, standardKycCallbackResponse, openAddressDetailsModal, isAddressSaved } =
        useAppSelector((state: RootState): ConsentState => state.consent);
    const kycType = params.kycType;
    const kycStatus = searchQuery.get("status") === "true" ? "success" : "failed";
    const reqId = searchQuery.get("state");

    console.log(isAddressSaved);

    useEffect(() => {
        if(kycStatus !== "success" || isAddressSaved){
            if (kycType === "ekyc") {
                dispatch(
                    callbackConsentRequestAsync({
                        requestId: params.trnxId as string,
                        search: searchQuery,
                    })
                );
            }
    
            if (kycType === "standard") {
                dispatch(standardKycCallbackRequestAsync(params.trnxId as string));
            }
        }
    }, [kycStatus, isAddressSaved]);

    useEffect(() => {
        if (
            callbackResult &&
            callbackResult.redirectStatus &&
            callbackResult.redirectLink &&
            !openAddressDetailsModal
        ) {
            setTimeout(() => {
                window.location.replace(callbackResult.redirectLink);
            }, 5000);
            setCountdown(5);
        }
    }, [callbackResult, openAddressDetailsModal]);

    useEffect(() => {
        if (
            standardKycCallbackResponse &&
            standardKycCallbackResponse.ClientRedirectionUrl &&
            !openAddressDetailsModal
        ) {
            setTimeout(() => {
                window.location.replace(
                    standardKycCallbackResponse.ClientRedirectionUrl
                );
            }, 5000);
            setCountdown(5);
        }
    }, [standardKycCallbackResponse, openAddressDetailsModal]);

    useEffect(() => {
        if (countDown > 0 && !openAddressDetailsModal) {
            setTimeout(() => {
                setCountdown(countDown - 1);
            }, 1000);
        }
    }, [countDown, openAddressDetailsModal]);

    useEffect(() => {
        if(kycStatus === "success"){
            dispatch(fetchAddressDetails({RequestId: reqId as string, Status: kycStatus}) as any);
        }
    }, [kycStatus]);

    return (
        <React.Fragment>
            <Grid className="header">
                <img alt="logo" src={logo} className="edgro-logo"></img>
            </Grid>
            <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="consent-callback-container"
            >
                <Card raised={true} className="result-card">
                    <CardContent className="card-content">
                        {loading === "loading" ? (
                            <Grid className="loader">
                                <CircularProgress />
                            </Grid>
                        ) : error ? (
                            "Error Occured : " + error
                        ) : openAddressDetailsModal ? <AddressVerificationModal open={true} />
                        : standardKycCallbackResponse || callbackResult ? (
                            <React.Fragment>
                                <Box className="verified-logo-container">
                                    <img
                                        alt="verified"
                                        src={verifiedLogo}
                                        className="verified-logo"
                                    ></img>
                                </Box>
                                <span className="card-details">
                                    <h1>Thank you!!</h1>
                                    <p>
                                        Your
                                        {kycType === "standard" &&
                                            " Standard KYC "}
                                        {kycType === "ekyc" &&
                                            " Digilocker KYC "}
                                        {kycType === "vcip" && " Video "}
                                        verification is done.
                                    </p>
                                </span>
                            </React.Fragment>
                        ) : null}
                    </CardContent>
                </Card>
                {standardKycCallbackResponse || callbackResult ? (
                    <span className="card-message">
                        You will be redirected back to Propelld in {countDown} seconds.
                    </span>
                ) : null}
            </Grid>
        </React.Fragment>
    );
}

export default ConsentCallback;
