import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress
} from "@mui/material";
import { ConsentState, saveAddressDetails } from "../features/consent/redux/ConsentSlice";
import { SaveAddressDetails } from "../features/consent/apis/models/SaveAddressDetails";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { useLocation, useParams } from "react-router-dom";

interface AddressVerificationModalProps {
  open: boolean;
}

const AddressVerificationModal: React.FC<AddressVerificationModalProps> = ({ open }) => {
  const params = useParams();
  const searchQuery = new URLSearchParams(useLocation().search);
  const requestId = searchQuery.get("state");
  const kycType = params.kycType;

  const [addressDetails, setAddressDetails] = useState<SaveAddressDetails>({
    Name: "",
    HouseNo: "",
    AddressLine: "",
    Pincode: "" as any,
    State: "",
    Country: "",
    RequestId: String(requestId),
    Type: kycType === "ekyc" ? "insta" : "standard"
  });

  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { fetchedAddressDetails, loading } = useAppSelector((state: RootState): ConsentState => state.consent);

  // Sync fetched address details with state
  useEffect(() => {
    if (fetchedAddressDetails) {
      setAddressDetails({
        ...addressDetails,
        Name: fetchedAddressDetails?.Name || "",
        HouseNo: fetchedAddressDetails?.House || "",
        AddressLine: fetchedAddressDetails?.Address || "",
        Pincode: Number(fetchedAddressDetails?.Pincode) || Number(""),
        State: "",
        Country: fetchedAddressDetails?.Country || "",
        RequestId: String(requestId)
      });
    }
  }, [fetchedAddressDetails]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddressDetails({
      ...addressDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    dispatch(saveAddressDetails(addressDetails) as any);
  };

  return (
    <Modal open={open} aria-labelledby="address-verification-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: isMobile ? 2 : 4
        }}
      >
        <Typography variant="h6" component="h2" textAlign="center">
          Please verify your Address Details below
        </Typography>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name on address proof"
              name="Name"
              value={addressDetails.Name}
              onChange={handleChange}
              placeholder="RAMESH KUMAR"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="House No./ Flat No."
              name="HouseNo"
              value={addressDetails.HouseNo}
              onChange={handleChange}
              placeholder="House No"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address Line 2"
              name="AddressLine"
              value={addressDetails.AddressLine}
              onChange={handleChange}
              placeholder="Address Line"
              multiline
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Pincode"
              name="Pincode"
              value={addressDetails.Pincode}
              onChange={handleChange}
              placeholder="Pincode"
              required
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="State"
              name="State"
              value={addressDetails.State}
              onChange={handleChange}
              placeholder="State"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Country"
              name="Country"
              value={addressDetails.Country}
              onChange={handleChange}
              placeholder="Country"
              required
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Verify
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddressVerificationModal;
